const randomHex = require('random-hex-color')

const boxShadow = require('./box-shadow')
const { randomBool, randomInt } = require('../util')

module.exports = () => {
  return {
    color: 'white',
    backgroundColor: 'rebeccapurple',
    fontSize: '1rem',
    padding: '.5rem 1rem',
    boxShadow: randomBool() ? boxShadow() : 'none',
    borderRadius: randomBool() ? 0 : randomInt(0, 12)
  }
}
