import React, { useState, useEffect } from 'react'

import H5 from '../elements/H5'
import Textarea from '../elements/Textarea'
import Input from '../elements/Input'
import Span from '../elements/Span'
import Flex from '../components/Flex'
import SectionBorder from '../components/SectionBorder'
import Lock from '../components/Lock'
import Layout from '../components/VoteLayout'
import Label from '../components/Label'
import Color from '../components/controls/Color'

import VoteButton from '../components/vote-button'
import SidebarHeading from '../components/heading-sidebar'

import randomButton from '../lib/generate/button'
import { db } from '../lib/client'
import { UP_KEYCODE, DOWN_KEYCODE } from '../lib/constants'

const Main = ({ button, text = 'Hello, world!' }) => (
  <div
    css={{
      textAlign: 'center',
      margin: '80px'
    }}
  >
    <a css={{ ...button, margin: 'auto 80px' }}>{text}</a>
  </div>
)

const Sidebar = ({ onChange, value, pins, onPin }) => {
  const changeValue = key => e => {
    onChange(key, e.target.value)
  }

  return (
    <>
      <SidebarHeading>Button</SidebarHeading>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.degrees}
          onClick={() => onPin('degrees', value.degrees)}
        />
        <Label width={128} mr={3}>
          Degrees
        </Label>
        <Input
          min={0}
          max={359}
          width={1}
          steps={1}
          mr={3}
          value={Number(value.degrees)}
          type="range"
          onChange={changeValue('degrees')}
        />
        <Span
          width={48}
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.degrees}
        />
      </Flex>
      <Color
        value={value.startColor}
        onChange={changeValue('startColor')}
        active={pins.startColor}
        onLock={() => onPin('startColor', value.startColor)}
      />
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.startColorHinting}
          onClick={() => onPin('startColorHinting', value.startColorHinting)}
        />
        <Label mr={3}>Hinting</Label>
        <Input
          type="range"
          min={0}
          max={100}
          value={value.startColorHinting}
          onChange={changeValue('startColorHinting')}
        />
      </Flex>
      <Color
        value={value.endColor}
        onChange={changeValue('endColor')}
        active={pins.endColor}
        onLock={() => onPin('endColor', value.endColor)}
      />
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.endColorHinting}
          onClick={() => onPin('endColorHinting', value.endColorHinting)}
        />
        <Label mr={3}>Hinting</Label>
        <Input
          type="range"
          min={0}
          max={100}
          value={value.endColorHinting}
          onChange={changeValue('endColorHinting')}
        />
      </Flex>
      <SectionBorder my={4} />
      <H5 mb={1}>Css</H5>
      <Textarea
        bg="transparent"
        height={64}
        width={1}
        border="1px solid rgba(0,0,0,.25)"
        p={2}
        readOnly
        value={
          'background-image: linear-gradient(' +
          value.degrees +
          'deg, ' +
          value.startColor +
          ' ' +
          value.startColorHinting +
          '%, ' +
          value.endColor +
          ' ' +
          value.endColorHinting +
          '%)'
        }
      />
      <H5 mb={1}>Js</H5>
      <Textarea
        bg="transparent"
        height={128}
        width={1}
        border="1px solid rgba(0,0,0,.25)"
        p={2}
        readOnly
        value={
          'import styled from "@emotion/styled"\n\n' +
          "const Gradient = styled('div')({\n" +
          '  backgroundImage: "linear-gradient(' +
          value.degrees +
          'deg, ' +
          value.startColor +
          ' ' +
          value.startColorHinting +
          '%, ' +
          value.endColor +
          ' ' +
          value.endColorHinting +
          '%)"\n' +
          '})\n'
        }
      />
    </>
  )
}

export default () => {
  const [button, setButton] = useState(randomButton())
  const [pins, setPins] = useState({})

  const fullButton = { ...button, ...pins }

  const upvote = () => {
    db.collection('components')
      .doc('buttons')
      .collection('votes')
      .add({
        up: true,
        data: fullButton,
        metadata: { pins }
      })

    setButton(randomButton())
  }
  const downvote = () => {
    db.collection('components')
      .doc('buttons')
      .collection('votes')
      .add({
        up: false,
        data: fullButton,
        metadata: { pins }
      })

    setButton(randomButton())
  }
  const skip = () => {
    setButton(randomButton())
  }

  const handleVoteKey = e => {
    if (e.keyCode === UP_KEYCODE || e.keyCode === DOWN_KEYCODE) {
      e.preventDefault && e.preventDefault()
    } else {
      return
    }

    if (e.keyCode === UP_KEYCODE) {
      upvote()
    } else if (e.keyCode === DOWN_KEYCODE) {
      downvote()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleVoteKey)

    return () => window.removeEventListener('keydown', handleVoteKey)
  })

  return (
    <Layout
      main={<Main button={fullButton} />}
      sidebar={
        <Sidebar
          value={fullButton}
          pins={pins}
          onChange={(key, value) => {
            setButton({
              ...button,
              [key]: value
            })

            setPins({
              ...pins,
              [key]: value
            })
          }}
          onPin={(key, value) => {
            if (!pins[key]) {
              setPins({
                ...pins,
                [key]: value
              })
            } else {
              const newPins = { ...pins }
              delete newPins[key]
              setPins(newPins)
            }
          }}
        />
      }
      footer={
        <>
          <Flex>
            <VoteButton onClick={upvote}>Up Vote</VoteButton>
            <VoteButton onClick={downvote}>Down Vote</VoteButton>
            <VoteButton onClick={skip}>Skip</VoteButton>
          </Flex>
        </>
      }
    />
  )
}
